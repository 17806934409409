(function () {
  angular.module('kmi.lms.course.purchase').component('cscCodePaymentModalComponent', {
    controller: CscCodePaymentModalController,
    controllerAs: 'vm',
    bindings: {
      modalInstance: '=',
    },
  });

  /* @ngInject */
  function CscCodePaymentModalController() {
    var vm = this;

    vm.close = closeModal;

    function closeModal() {
      vm.modalInstance.close();
    }
  }
})();
