(function () {
  angular.module('kmi.lms.course.purchase').component('coursePurchaseComponent', {
    template: require('ajs/modules/course/purchase/wizard/purchase.html').default,
    controller: CoursePurchaseController,
    controllerAs: 'vm',
    bindings: {
      course: '=',
      purchaseWizardService: '=',
    },
  });

  /* @ngInject */
  function CoursePurchaseController(_, backUrlService, purchaseService) {
    var vm = this;
    vm.$onInit = onInit;

    function onInit() {
      vm.wizard = vm.purchaseWizardService;
      vm.steps = vm.purchaseWizardService.steps;

      vm.wizard.cart = [
        {
          id: vm.course.id,
          name: vm.course.name,
          typeId: 3,
          price: _.get(vm.course, 'price.amount'),
          customData: {
            description: vm.course.$getHtmlField('description'),
            priceTypeId: _.get(vm.course, 'price.type_id'),
            accessPeriod: _.get(vm.course, 'price.accessPeriod'),
          },
        },
      ];

      purchaseService.initRegistrations(vm.wizard.cart);

      // Subscribe on the wizard's "navigate" event in order to switch wizard states
      vm.purchaseWizardService.on('navigate', function (target) {
        // "purchaseId" parameter required for the "result" step. It becomes available on the Step 2 after security token generation
        backUrlService.passThroughRedirect(target.state, {
          id: vm.course.id,
          purchaseId: _.get(vm.wizard, 'purchase.id'),
        });
      });
    }
  }
})();
