(function () {
  angular.module('kmi.lms.course.purchase').directive('coursePurchaseCartView', coursePurchaseCartView);

  /* @ngInject */
  function coursePurchaseCartView() {
    return {
      restrict: 'EA',
      scope: {
        wizard: '=',
      },
      template: require('ajs/modules/course/purchase/wizard/cart/cart-view.html').default,
      controller: CoursePurchaseCartViewController,
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  /* @ngInject */
  function CoursePurchaseCartViewController() {
    var vm = this;

    vm.abs = Math.abs;
  }
})();
