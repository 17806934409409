(function () {
  angular.module('kmi.lms.course.purchase').component('coursePurchaseUserConfirmationComponent', {
    template: require('ajs/modules/course/purchase/wizard/payment/modal/purchase-confirmation-modal.html').default,
    controller: CoursePurchaseUserConfirmationController,
    controllerAs: 'vm',
    bindings: {
      modalInstance: '=',
    },
  });

  /* @ngInject */
  function CoursePurchaseUserConfirmationController($state, currentUser, globalConfig) {
    var vm = this;

    vm.user = currentUser.get();
    vm.signInWording = globalConfig.signInWording;

    vm.close = closeModal;
    vm.logout = logout;

    function closeModal() {
      vm.modalInstance.close();
    }

    function logout() {
      $state.go('logout');
    }
  }
})();
