(function () {
  angular.module('kmi.lms.course.purchase').factory('purchaseService', purchaseService);

  /* @ngInject */
  function purchaseService(LearningObjectRegistration, moment) {
    return {
      initRegistrations: function (cart) {
        cart.forEach(function (item) {
          initCourseRegistration(item);
        });
      },
    };

    function initCourseRegistration(item) {
      // Get user's course registration data
      LearningObjectRegistration.get(item).then(function (registration) {
        item.customData.registration = registration;

        // Calculate course expiration date depending on the existing expiration date and price properties
        if (item.price && item.customData.accessPeriod) {
          item.customData.expirationDate = getExpirationDate(
            registration.aggregated ? registration.aggregated.accessExpirationDate : null,
            item.customData.accessPeriod,
          );
        }
      });

      function getExpirationDate(date, days) {
        var today = new Date();
        return moment(moment(date || today).diff(today, 'days') > 0 ? date : today)
          .add(days, 'days')
          .hour(23)
          .minute(59)
          .format('MMM D, YYYY hh:mm a');
      }
    }
  }
})();
