(function () {
  angular.module('kmi.lms.course.purchase').component('coursePurchasePaymentComponent', {
    template: require('ajs/modules/course/purchase/wizard/payment/payment-info.html').default,
    controller: CoursePurchasePaymentController,
    controllerAs: 'vm',
    bindings: {
      purchaseWizardService: '=',
    },
  });

  /* @ngInject */
  function CoursePurchasePaymentController(
    $scope,
    $uibModal,
    $window,
    currentUser,
    geoService,
    $state,
    notificationService,
    serverErrorHandlerService,
    CourseCouponCodeAvailability,
    Purchase,
    _,
    globalConfig,
    securityService,
  ) {
    let vm = this;
    vm.$onInit = onInit;
    vm.currentYear = parseInt(new Date().getFullYear().toString().substring(2), 10);
    vm.openTermsModal = openTermsModal;
    vm.validateCouponCode = validateCouponCode;
    vm.createPurchase = createPurchase;
    vm.isStateAvailable = securityService.isStateAvailable;

    function onInit() {
      vm.wizard = vm.purchaseWizardService;
      vm.wizard.purchase = null;
      if (currentUser.get().anonymous || !vm.wizard.cart[0].price) {
        // Go to the first wizard step because on this step user should be logged in and course should have a price
        vm.wizard.renewPurchase();
      }

      if (globalConfig.settings && globalConfig.settings.course && globalConfig.settings.course.purchaseConfirmation) {
        $uibModal.open({
          component: 'coursePurchaseUserConfirmationComponent',
          backdrop: 'static',
          keyboard: false,
        });
      }
    }

    function showError(errorReason) {
      return serverErrorHandlerService.handleForbiddenError(errorReason, function (data) {
        vm.error = data;
      });
    }

    function validateCouponCode(code) {
      vm.validating = CourseCouponCodeAvailability.get(
        { courseId: vm.wizard.cart[0].id, code: code },
        addCouponToCart,
        showError,
      ).$promise.finally(function () {
        vm.validating = null;
      });

      function addCouponToCart(result) {
        vm.wizard.coupon.valid = result.valid;
        vm.wizard.coupon.error = result.error;

        if (result.valid) {
          vm.wizard.cart.push({
            name: 'Code: ' + vm.wizard.coupon.code,
            code: vm.wizard.coupon.code,
            typeId: 1,
            price: -vm.wizard.cart[0].price,
          });
        }
      }
    }

    function createPurchase() {
      vm.submitted = true;

      if (!vm.termsConfirm) {
        // Show terms of use if user is not confirmed that yet
        openTermsModal();
        return;
      }

      processPurchase();
    }

    function openTermsModal() {
      $uibModal.open({
        component: 'termsOfUseModalComponent',
      });
    }

    function processPurchase() {
      let cart = vm.wizard.cart.filter((item) => item.typeId !== 1);
      let discountCodes = vm.wizard.cart.filter((item) => item.typeId === 1);
      let purchase = Purchase.newInstance({
        cart: _.map(cart, function (cartItem) {
          delete cartItem.customData;
          return cartItem;
        }),
        discountCodes: _.map(discountCodes, function (cartItem) {
          return cartItem.code;
        }),
        returnUrl: vm.wizard.getResultPathTemplate(),
        payment: {
          cancelUrl: [
            $window.location.origin,
            $state.href('main.coursePurchase.cart', { courseId: vm.wizard.cart[0].id }),
          ].join(''),
        },
      });

      vm.submissionProgress = purchase
        .$save()
        .then(purchaseSuccess, purchaseError)
        .finally(function () {
          vm.submissionProgress = null;
        });

      function purchaseSuccess() {
        vm.wizard.purchase = purchase;

        if (purchase.statusId === 1) {
          if (purchase.payment) {
            if (purchase.payment.paymentGateway.typeId === 3) {
              vm.securityToken = purchase.payment.securityToken;
              vm.securityTokenId = purchase.payment.securityTokenId;
              vm.processorEndpoint = purchase.payment.processorEndpoint;

              $scope.$broadcast('event:purchase.checkout');
            } else {
              $window.location.href = purchase.payment.processorEndpoint;
            }
          } else {
            notificationService.error('Payment system is unavailable', 5e3);
          }
        } else {
          vm.wizard.nextStep(); // Show results page
        }
      }

      function purchaseError(errorReason) {
        return serverErrorHandlerService.handleForbiddenError(errorReason, function (data) {
          vm.error = data.error;

          if (data.cart) {
            vm.wizard.applyResponse(data.cart);
          }
        });
      }
    }
  }
})();
