(function () {
  angular.module('kmi.lms.course.purchase').component('coursePurchaseCreateAccountComponent', {
    template: require('ajs/modules/course/purchase/wizard/user-account/creation-account.html').default,
    controller: CoursePurchaseCreateAccountController,
    controllerAs: 'vm',
    bindings: {
      purchaseWizardService: '=',
      currentUser: '<',
    },
  });

  /* @ngInject */
  function CoursePurchaseCreateAccountController(
    $scope,
    $state,
    User,
    elmsAuthService,
    purchaseService,
    notificationService,
    navigationService,
    globalConfig,
    _,
  ) {
    var vm = this;
    vm.$onInit = onInit;

    vm.signInWording = globalConfig.signInWording;

    vm.saveAccount = saveAccount;
    vm.login = login;

    function onInit() {
      vm.wizard = vm.purchaseWizardService;
      vm.regState = vm.currentUser.anonymous ? 'register' : 'confirm';
      vm.user = vm.currentUser.anonymous
        ? User.newUser({
            country: 'US',
            timeZoneId: 86,
          })
        : angular.copy(vm.currentUser);

      // Drop wizard to the first step if user is not anonymous. Create account step is not suitable for him
      if (
        !_.find(vm.purchaseWizardService.steps, function (step) {
          return $state.current.name === step.state;
        })
      ) {
        vm.purchaseWizardService.renewPurchase();
      }

      $scope.$on('event:user.saved', function (event, user) {
        vm.regState = 'pending';
        vm.user = user;

        elmsAuthService.login({
          username: user.loginName,
          password: user.password,
        });
      });

      $scope.$on('elmsAuthService.notification.login', function () {
        // Retrieve course registration data for the just logged in user
        vm.regState = 'confirm';
        purchaseService.initRegistrations(vm.wizard.cart);
      });

      $scope.$on('elmsAuthService.error.login', function (event, message) {
        vm.regState = 'confirm';
        notificationService.error(message, 5e3);
      });
    }

    function saveAccount() {
      $scope.$broadcast('event:user.save');
    }

    function login() {
      navigationService.setTargetState({
        name: 'main.coursePurchase.payment',
        params: { courseId: vm.wizard.cart[0].id },
      });
      $state.go('prompt.login');
    }
  }
})();
