(function () {
  angular.module('kmi.lms.course.purchase').component('termsOfUseModalComponent', {
    template: require('ajs/modules/course/purchase/wizard/payment/modal/terms-of-use-modal.html').default,
    controller: TermsOfUseModalController,
    controllerAs: 'vm',
    bindings: {
      modalInstance: '=',
    },
  });

  /* @ngInject */
  function TermsOfUseModalController() {
    var vm = this;

    vm.close = closeModal;

    function closeModal() {
      vm.modalInstance.close();
    }
  }
})();
