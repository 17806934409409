(function () {
  /**
   * @ngdoc service
   * @name kmi.lms.ubt.course.purchase.service:PurchaseWizard
   *
   * @description
   * Contains methods to operate purchase on the each wizard step
   */

  angular.module('kmi.lms.course.purchase').factory('PurchaseWizard', PurchaseWizardService);

  /* @ngInject */
  function PurchaseWizardService($state, $uiRouterGlobals, $window, backUrlService, EventDispatcher, _) {
    function PurchaseWizard(attrs) {
      this.steps = [];
      this.cart = [];
      this.coupon = {};

      // Apply options passed to the constructor
      _.extend(this, attrs);

      EventDispatcher.call(this);
    }

    _.extend(PurchaseWizard.prototype, {
      /**
       * @description
       * Redirects wizard to the next step
       */
      nextStep: function () {
        var restSteps = _.slice(this.steps, this.stepIndex() + 1);

        if (restSteps && restSteps.length) {
          this.dispatchEvent('navigate', { state: restSteps[0].state });
        }
      },
      /**
       * @description
       * Redirects wizard to the previous step
       */
      prevStep: function () {
        var s = this.steps;
        var initialSteps = _.dropRight(s, s.length - this.stepIndex());
        if (initialSteps && initialSteps.length) {
          this.dispatchEvent('navigate', { state: initialSteps[initialSteps.length - 1].state });
        }
      },
      /**
       * @description
       * Returns current step of the wizard
       * @returns {*}
       */
      step: function () {
        return _.find(this.steps, function (item) {
          return $state.is(item.state, $uiRouterGlobals.params) || $state.includes(item.state, $uiRouterGlobals.params);
        });
      },
      /**
       * @description
       * Checks whether provided step is currently active
       * @param step
       * @returns {*|boolean}
       */
      stepActive: function (step) {
        return $state.is(step.state);
      },
      /**
       * @description
       * Returns index of the currently active step
       *
       * @returns {*}
       */
      stepIndex: function () {
        var index = null;
        _.forEach(this.steps, function (item, idx) {
          if ($state.is(item.state, $uiRouterGlobals.params) || $state.includes(item.state, $uiRouterGlobals.params)) {
            index = idx;
          }
        });

        return index;
      },
      /**
       * @description
       * Returns wizard progress depending on the current step
       * @returns {number}
       */
      progress: function () {
        return parseInt((this.stepIndex() / (this.steps.length - 1)) * 100, 10);
      },
      getTotalAmount: function () {
        return _.sumBy(this.cart, 'price');
      },
      /**
       * @description
       * Drops wizard to the first step
       */
      renewPurchase: function () {
        this.purchase = null;
        this.dispatchEvent('navigate', { state: this.steps[0].state });
      },
      cancelPurchase: function () {
        backUrlService.goBack();
      },
      applyResponse: function (cartResponse) {
        this.cart.forEach((cartItem) => {
          let responseItem = cartResponse.find((item) => {
            return item.id === cartItem.id && item.typeId === cartItem.typeId;
          });
          if (responseItem && responseItem['error']) {
            cartItem['error'] = responseItem['error'];
          } else {
            if ('error' in cartItem) {
              delete cartItem.error;
            }
          }
        });
      },
      getResultPathTemplate: function () {
        return [
          '{0}',
          $state.href('main.coursePurchase.result', { courseId: this.cart[0].id, purchaseId: '{1}' }),
        ].join('');
      },
    });

    return PurchaseWizard;
  }
})();
