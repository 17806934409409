(function () {
  angular.module('kmi.lms.course.purchase').component('coursePurchaseResultComponent', {
    template: require('ajs/modules/course/purchase/wizard/result/result.html').default,
    controller: CoursePurchaseResultController,
    controllerAs: 'vm',
    bindings: {
      purchase: '=',
      course: '=',
      purchaseWizardService: '=',
      user: '<',
    },
  });

  /* @ngInject */
  function CoursePurchaseResultController($window, globalConfig) {
    var vm = this;
    vm.$onInit = onInit;

    vm.contacts = globalConfig.contacts;

    vm.printReceipt = printReceipt;
    vm.abs = Math.abs;

    function onInit() {
      vm.wizard = vm.purchaseWizardService;
      vm.coursePurchase = vm.purchase;
      vm.currentUser = vm.user;
    }

    function printReceipt() {
      $window.print();
    }
  }
})();
