(function () {
  angular.module('kmi.lms.course.purchase').component('coursePurchaseCartComponent', {
    template: require('ajs/modules/course/purchase/wizard/cart/cart.html').default,
    controller: CoursePurchaseCartController,
    controllerAs: 'vm',
    bindings: {
      purchaseWizardService: '=',
    },
  });

  /* @ngInject */
  function CoursePurchaseCartController() {
    var vm = this;
    vm.$onInit = onInit;

    function onInit() {
      vm.wizard = vm.purchaseWizardService;
    }
  }
})();
